import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../layouts/Layout"

import { DiscussionEmbed } from "disqus-react"

import SiteMetadata from "../components/SiteMetadata"

import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import MarkdownIt from "markdown-it"
import Prism from "prismjs"

import "../styles/prism.css"

const md = new MarkdownIt({
  html: true,
  javascript: true,
  linkify: false,
})

const BlogTemplate = ({ data }) => {
  useEffect(() => {
    Prism.highlightAll()
  }, [])

  const blog = data.contentfulBlogPost
  const options = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        let title = ""

        if (
          node.data.target &&
          node.data.target.fields &&
          node.data.target.fields.title &&
          node.data.target.fields.title["en-US"]
        ) {
          title = node.data.target.fields.title["en-US"]
        }
        return (
          <div>
            <img
              src={`https:${node.data.target.fields.file["en-US"].url}`}
              alt={title}
              className="w-full"
            />
          </div>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const code = node.data.target.fields.codeSnippet["en-US"]
        return <div dangerouslySetInnerHTML={{ __html: md.render(code) }} />
      },
    },
    [BLOCKS.EMBEDDED_ASSET]: node => (
      <img
        src={`https:${node.data.target.fields.file["en-US"].url}`}
        alt={blog.title}
      />
    ),
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
  }

  return (
    <Layout>
      <SiteMetadata
        title={`${blog.title} - Nick Partridge`}
        description={`${blog.blogExcerptv2.blogExcerptv2}`}
      />
      <div className="post__container px-4 md:px-0">
        <Link to="/" className="floating-article-link">
          Nick Partridge
        </Link>

        <h1 className="md:text-5xl text-4xl font-bold my-8 text-center">
          {blog.title}
        </h1>
        {documentToReactComponents(
          blog.childContentfulBlogPostBodyRichTextNode.json,
          options
        )}
        <div className="mt-6">
          <DiscussionEmbed
            shortname="portfolio-nqwmeby31l"
            config={{
              identifier: blog.slug,
              title: blog.title,
              language: "en_US",
            }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SpecificBlogPost($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      blogExcerptv2 {
        blogExcerptv2
      }
      childContentfulBlogPostBodyRichTextNode {
        json
      }
    }
  }
`
export default BlogTemplate
